import 'chatnext-ui/dist/index'
import { get_bookmarks } from './search_bookmark'
import './fixes/capture'
import { addOCRMenuItem } from './ocr'
import { get_chatnext_assistant_action_list } from './chatnext_assistant_action_list'

frappe.provide('frappe.search')

if (window.isApp) {
  window.defaultOpen = window.open

  window.open = (url, target, windowFeatures) => {
    if (url[0] === '/') {
      window.defaultOpen(url, target, windowFeatures)
    } else {
      //   open webBrowser here!
      window?.nativeInterface?.execute('openWebBrowser', {
        url,
        target,
        windowFeatures,
      })
    }
  }
}

window.nativeInterface.execute('getPushToken').then((r) => {
  frappe.call({
    method: 'nextai.token.register',
    args: {
      token: r,
    },
    callback: (r) => {},
  })
})

new Promise((resolve, reject) => {
  frappe.call({
    method: 'frappe.client.get_list',
    args: {
      doctype: 'Chatnext Bookmark',
      fields: '*',
    },
    callback: (r) => {
      window.frappe.boot.chatnextBookmarks = [...r.message]
      resolve()
    },
    error: (e) => {
      reject(e)
    },
  })
})
  .then(async () => {
    await frappe.call({
      method:
        'nextai.nextai.doctype.chatnext_settings.chatnext_settings.is_assistant_enabled',
      callback: async (r) => {
        if (r.message == 1) {
          // Add csrf token to the window object so that react-frappe-sdk can access it
          window.csrf_token = window.frappe.csrf_token

          let el

          if (frappe.boot.desk_settings.search_bar === 1) {
            el = document.createElement('chatnext-app')
            el.logo_img = '/assets/nextai/assets/icons/chatnext_logo.png'
            el.apiExecutor = function (obj) {
              console.log('executed', obj)
            }
            el.getChatContextRoute = function () {
              return frappe.get_route()
            }
          }

          function deduplicate(options) {
            const out = [],
              routes = []
            options.forEach(function (option) {
              if (option.route) {
                if (
                  option.route[0] === 'List' &&
                  option.route[2] !== 'Report' &&
                  option.route[2] !== 'Inbox'
                ) {
                  option.route.splice(2)
                }

                const str_route =
                  typeof option.route === 'string'
                    ? option.route
                    : option.route.join('/')
                if (routes.indexOf(str_route) === -1) {
                  out.push(option)
                  routes.push(str_route)
                } else {
                  const old = routes.indexOf(str_route)
                  if (out[old].index < option.index && !option.recent) {
                    out[old] = option
                  }
                }
              } else {
                out.push(option)
                routes.push('')
              }
            })
            return out
          }

          // Get search context will be added @getSearchContext
          const handleRouteClick = function (route) {
            frappe.set_route(route)
          }

          const getSearchContext = async function (obj) {
            const assistant_action_list =
              await get_chatnext_assistant_action_list(obj)

            if (obj.length <= 1) {
              if (assistant_action_list.length) {
                return {
                  results: assistant_action_list,
                }
              }
              return {
                results: [],
              }
            }

            const options = frappe.search.utils
              .get_creatables(obj)
              .concat(
                get_bookmarks(obj),
                frappe.search.utils.get_search_in_list(obj),
                frappe.search.utils.get_doctypes(obj),
                frappe.search.utils.get_reports(obj),
                frappe.search.utils.get_pages(obj),
                frappe.search.utils.get_workspaces(obj),
                frappe.search.utils.get_dashboards(obj),
                frappe.search.utils.get_recent_pages(obj || ''),
                frappe.search.utils.get_executables(obj)
              )
            options.sort(function (a, b) {
              return b.index - a.index
            })

            return Promise.resolve({
              results: deduplicate(options).concat(assistant_action_list),
            })
          }

          el.handleRouteClick = handleRouteClick
          el.getSearchContext = getSearchContext

          el.handleNewClick = (item) => {
            item.onclick(item.match)
          }

          document.body.appendChild(el)
          localStorage.setItem('isChatNextEnabled', true)
        } else {
          setTimeout(() => {
            const frappeAwesoneBar =
              document?.body?.getElementsByClassName('search-bar')?.[0]
            frappeAwesoneBar && (frappeAwesoneBar.style.display = 'flex')
          }, 50)
          localStorage.setItem('isChatNextEnabled', false)
        }
      },
    })
    frappe.call({
      method:
        'nextai.nextai.doctype.chatnext_settings.chatnext_settings.is_todo_enabled',
      callback: (r) => {
        if (r.message == 1) {
          const taskManagerIcon = document.querySelector('.task-manager-icon')
          if (!taskManagerIcon) {
            const navListParent = document.querySelector(
              '.navbar-collapse .navbar-nav'
            )
            const listItem = document.createElement('li')
            listItem.innerHTML = `
            <a
            aria-haspopup="true"
            aria-expanded="true"
            href="/app/task_manager"
            >
            <span >
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.628571" y="0.628571" width="13.7429" height="13.7429" rx="1.57606" stroke="black" stroke-width="0.742857"/>
            <g clip-path="url(#clip0_1227_322)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.28073 7.48505L4.79053 5.93141C4.29304 5.41272 3.48644 5.41272 2.98894 5.93141L2.37312 6.57344C1.87563 7.09212 1.87563 7.93307 2.37312 8.45175L5.40333 11.611C5.90082 12.1297 6.70742 12.1297 7.20493 11.611L7.82074 10.969C7.89121 10.8955 7.95171 10.8155 8.00221 10.731L12.6269 5.90936C13.1244 5.39068 13.1244 4.54973 12.6269 4.03105L12.0111 3.38901C11.5136 2.87033 10.707 2.87033 10.2095 3.38901L6.28073 7.48505ZM4.77495 10.0199L10.6899 3.88989C10.9221 3.64784 11.2985 3.64784 11.5306 3.88989L12.1464 4.53193C12.3786 4.77399 12.3786 5.16643 12.1464 5.40848L6.74565 11.0354C6.48643 11.3055 6.06863 11.3127 5.80235 11.0501C5.46281 10.7154 5.02456 10.2801 4.77495 10.0199ZM4.31011 9.53341L2.85355 7.95087C2.62138 7.70881 2.62138 7.31637 2.85355 7.07432L3.46936 6.43229C3.70154 6.19024 4.07795 6.19024 4.31011 6.43229L5.80029 7.98593L4.77495 9.05495C4.61873 9.2178 4.48727 9.35633 4.31011 9.53341Z" fill="black"/>
            </g>
            <defs>
            <clipPath id="clip0_1227_322">
            <rect width="11" height="9" fill="white" transform="translate(2 3)"/>
            </clipPath>
            </defs>
            </svg>
            
            </span>
            
          </a>
            `

            listItem.setAttribute('class', 'nav-item task-manager-icon')
            navListParent.insertBefore(listItem, navListParent.firstChild)
          }
        }
      },
    })
  })
  .catch((e) => {
    console.error(e)
  })
// example of how to call native ui
// window.nativeInterface.execute("dialCall",{tel:"+918805189711"})

window.nativeInterface.nextAilogout = async function () {
  if (!window.isApp) {
    return frappe.app.logout()
  }
  const t = await window.nativeInterface.execute('getPushToken')
  //deregister token
  frappe.call({
    method: 'nextai.token.de_register',
    args: {
      token: {
        data: t,
      },
    },
    callback: function (r) {
      if (r.message) {
        window.nativeInterface.logToNative('Token Deleted')
      } else {
        console.log(r.message)
      }
    },
  })
  frappe.app.logout()
  setTimeout(() => {
    window.nativeInterface.execute('logout').catch((e) => {
      console.log(e)
    })
  }, 100)
}

window.nativeInterface.SyncContacts = async () => {
  window.nativeInterface
    .execute('getContacts')
    .then((data) => {
      let d = new frappe.ui.Dialog({
        title: 'Enter details',
        fields: data
          .map((contact) => {
            return {
              label: contact?.name || 'No Name',
              fieldname: JSON.stringify({ ...contact }),
              fieldtype: 'Check',
            }
          })
          .sort((a, b) => {
            const x = a?.label?.toLowerCase()
            const y = b?.label?.toLowerCase()
            if (x > y) return 1
            if (y > x) return -1
          }),
        size: 'small', // small, large, extra-large
        primary_action_label: 'Submit',
        primary_action(values) {
          const data = Object.keys(values).reduce((acc, key) => {
            if (values[key] === 1) {
              return [...acc, JSON.parse(key)]
            }
            return acc
          }, [])
          frappe.call({
            method: 'nextai.method.contact.insert_contacts',
            args: {
              contacts: data,
            },
            callback: () => {
              frappe.msgprint('Successfully Added selected Contacts!')
            },
          })
          d.hide()
        },
      })

      d.show()
    })
    .catch((e) => {
      alert(e)
    })
}

document.addEventListener('click', function () {
  setTimeout(() => {
    const ancherTabs = document.querySelectorAll(
      '.nav.nav-tabs.card-header-tabs .nav-item a'
    )
    ancherTabs?.forEach((anchorTag) => {
      anchorTag.removeAttribute('href')
    })
  }, 1000)
})

frappe.router.on('change', () => {
  if (
    window.isApp &&
    frappe.router.current_route?.[0].toLowerCase() === 'print'
  ) {
    requestMobilePrintUI()
  }

  setTimeout(addOCRMenuItem, 200)
})

const requestMobilePrintUI = () => {
  const paths = frappe.router.current_route
  if (paths?.[0].toLowerCase() === 'print' && paths.length >= 3) {
    console.log('Cur Page', cur_page?.page)
    cur_page?.page?.page?.clear_primary_action()
    cur_page?.page?.page?.clear_menu()

    cur_page?.page?.page?.add_menu_item('Print Settings', () => {
      frappe.set_route('Form', 'Print Settings')
    })
    cur_page?.page?.page?.add_menu_item(
      'Refresh',
      () => window.location.reload(),
      'octicon octicon-sync'
    )

    cur_page?.page?.page?.set_primary_action('Share', async () => {
      console.log('Share Clicked', frappe.router.current_route)
      const paths = frappe.router.current_route

      const format = $(
        "select[data-fieldname='print_format'],input[data-fieldname='print_format'] "
      )?.[0]?.value

      const language = $(
        "select[data-fieldname='language'],input[data-fieldname='language'] "
      )?.[0]?.value

      const letterhead = $(
        "select[data-fieldname='letterhead'],input[data-fieldname='letterhead'] "
      )?.[0]?.value

      const no_letterhead = letterhead && letterhead !== '' ? '0' : '1'

      const settings = JSON.stringify({})

      // get PDF Doc

      window.nativeInterface.execute('share', {
        mimeType: 'application/pdf',
        message: 'Invoice Report',
        url: `${
          window.location.origin
        }/api/method/frappe.utils.print_format.download_pdf?doctype=${
          paths[1]
        }&name=${paths[2]}&format=${
          format || 'Standard'
        }&no_letterhead=${no_letterhead}&letterhead=${
          letterhead || 'No Letterhead'
        }&settings=${settings}&_lang=${language || 'en'}`,
        type: 'download',
      })
    })
  }
}

window.requestMobilePrintUI = requestMobilePrintUI

window.parent?.postMessage(
  {
    action: 'GET_CSRF_TOKEN_RES',
    data: { csrf: frappe.csrf_token },
  },
  '*'
)

frappe.ui.form.on('User Password', {
  refresh(frm) {
    $('.form-assignments').hide()
    $('.form-attachments').hide()

    // frm.add_custom_button(__(''))
    // $(".form-shared").hide();
    // $(".form-tags").hide();
    // $(".form-sidebar-stats").hide();
    // $(".list-unstyled.sidebar-menu.text-muted").hide();
  },
})
