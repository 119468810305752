import { fuzzy_match } from './fuzzy_match'
import { bolden_match_part } from './search_bookmark'

const get_page_type = (routeDetails) => {
  const type = routeDetails?.[0] || ''
  switch (type) {
    case 'Form':
      return 'doctype'
    case 'List':
      return 'doclist'
    case 'Workspaces':
      return 'workspace'
    default:
      return ''
  }
}

export const get_chatnext_assistant_action_list = async (obj) => {
  const routeDetails = frappe.get_route()

  const chatnext_assistant_action_list = await frappe.call({
    method:
      'nextai.funnel.doctype.funnel_task.api.get_chatnext_assistant_trigger_list',
    args: {
      route: window.location.pathname,
      page_name: routeDetails?.[1] || '',
      page_type: get_page_type(routeDetails),
    },
  })

  const list =
    chatnext_assistant_action_list?.message?.map((item) => ({
      label: JSON.parse(item?.data || '{}')?.name_of_action || '',
      funnel_defination_id: item?.name || '',
    })) || []

  const out = []
  list.forEach((item, idx) => {
    const label = item?.label || ''
    let level = !obj ? 1 : fuzzy_match(obj, __(label))[1]
    if (level) {
      out.push({
        type: 'Assistant',
        label: !obj ? label : __(bolden_match_part(label, obj)),
        value: !obj ? label : __(label),
        index: !obj ? idx + 0.5 : level + 0.5,
        match: label,
        funnel_defination_id: item.funnel_defination_id,
      })
    }
  })
  return out
}
