export const addOCRMenuItem = async () => {
  if (frappe.router.current_route?.[0] === 'List') {
    const [list, doctype, list2] = frappe.router.current_route
    if (list === 'List' && list2 === 'List') {
      cur_list?.page?.add_menu_item(__('Generate OCR'), function () {
        const progressName = 'Converting OCR to document..'

        let uploader = new frappe.ui.FileUploader({
          on_success: async (data) => {
            const image = await fetch(data.file_url)
            const imageData = await image.blob()

            function blobToBase64(blob) {
              return new Promise((resolve, _) => {
                const reader = new FileReader()
                reader.onloadend = () => resolve(reader.result)
                reader.readAsDataURL(blob)
              })
            }

            const base64Image = await blobToBase64(imageData)

            frappe.msgprint({
              title: __('Document uploaded successfully.'),
              indicator: 'green',
              message: __('Please wait to create doc from document'),
            })

            frappe
              .call({
                method: 'nextai.chatbot.ocr_bot_request',
                type: 'POST',
                args: {
                  doc_name: doctype,
                  file: base64Image,
                },
                callback: (res) => {
                  console.log('RESPONSE', res)
                  if (res.message) {
                    if (res.message.exc_type && res.message.exception) {
                      return frappe.msgprint({
                        title: __(res.message.exc_type),
                        notification: 'red',
                        description: __(res.message.exception),
                      })
                    }

                    if (res.message.message) {
                      let json
                      try {
                        json = JSON.parse(res.message.message)
                      } catch (error) {
                        return frappe.throw(res.message.message)
                      }

                      // Create new doc page and open it
                      frappe.new_doc(doctype, json)
                    }
                  } else {
                    if (res.exc_type && res.exception) {
                      return frappe.msgprint({
                        title: __(res.exc_type),
                        notification: 'red',
                        description: __(res.exception),
                      })
                    }
                  }
                },
                error: (res) => {
                  if (res.message.exc_type && res.data.exception) {
                    throw new Error(res.data.exc_type)
                  } else {
                    throw new Error(
                      res?.data?.message || res?.data || res?.message || res
                    )
                  }
                },
              })
              .catch((e) => {
                debugger
                frappe.throw(e?.message || e)
              })
          },
          restrictions: { allowed_file_types: ['image/*', '.pdf'] },
        })
      })
    }
  }
}
