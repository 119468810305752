import { fuzzy_match } from './fuzzy_match'
export function bolden_match_part(str, subseq) {
  if (fuzzy_match(subseq, str)[0] === false) {
    return str
  }
  if (str.indexOf(subseq) == 0) {
    var tail = str.split(subseq)[1]
    return '<mark>' + subseq + '</mark>' + tail
  }
  var rendered = ''
  var str_orig = str
  var str_len = str.length
  str = str.toLowerCase()
  subseq = subseq.toLowerCase()

  outer: for (var i = 0, j = 0; i < subseq.length; i++) {
    var sub_ch = subseq.charCodeAt(i)
    while (j < str_len) {
      if (str.charCodeAt(j) === sub_ch) {
        var str_char = str_orig.charAt(j)
        if (str_char === str_char.toLowerCase()) {
          rendered += '<mark>' + subseq.charAt(i) + '</mark>'
        } else {
          rendered += '<mark>' + subseq.charAt(i).toUpperCase() + '</mark>'
        }
        j++
        continue outer
      }
      rendered += str_orig.charAt(j)
      j++
    }
    return str_orig
  }
  rendered += str_orig.slice(j)
  return rendered
}

export const get_bookmarks = (obj) => {
  const bookmark_data = frappe.boot.chatnextBookmarks
  const search_bookmarks_data = bookmark_data.map((x) => {
    return `${x.label} ${x.route}`
  })

  const out = []
  bookmark_data.forEach((item, index) => {
    let level, target
    level = fuzzy_match(obj, __(search_bookmarks_data[index]))[1]
    if (level) {
      target = item.label
      out.push({
        type: 'Bookmark',
        label: __([bolden_match_part(item.label, obj)]),
        value: __([target]),
        index: level + 0.5,
        match: search_bookmarks_data[index],
        onclick: function () {
          frappe.set_route(item.route)
        },
      })
    }
  })
  return out
}
